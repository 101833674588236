import {atom, selector, selectorFamily} from 'recoil';

import {GroupEnrollmentType, IGroupEnrollment, IGroupEnrollmentKey} from 'modules/group-enrollment/models';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';
import {readGroupEnrollmentList} from 'modules/group-enrollment/api';
import {CursorList} from 'shared/models/cursor-list';
import {tenantIdSelector} from 'modules/tenant/state/tenant-info';

export interface IGroupEnrollmentListFilters {
    groupId?: number;
    userId?: number;
    enrollmentType?: GroupEnrollmentType;
    invitedById?: number;
    isDeleted?: boolean;
    page: number;
    limit?: number;

    [key: string]: number | GroupEnrollmentType | boolean | undefined;
}

interface IGroupEnrollmentListState {
    groupEnrollments: IGroupEnrollment[];
    cursors: CursorList;
    more: boolean;
    filters: IGroupEnrollmentListFilters;

    _resetVerson: number;
}

export const groupEnrollmentListAtom = atom<IGroupEnrollmentListState | undefined>({
    key: 'groupEnrollmentListAtom',
    default: undefined,
});

export const groupEnrollmentListResetAtom = atom<number>({
    key: 'groupEnrollmentListResetAtom',
    default: 0,
});

export const groupEnrollmentListSelector = selectorFamily<IGroupEnrollmentListState | undefined, IGroupEnrollmentListFilters>({
    key: 'groupEnrollmentListSelector',
    get: (filters) => ({get}) => {
        const atomState = get(groupEnrollmentListAtom);
        const resetVersion = get(groupEnrollmentListResetAtom);
        if (
            atomState &&
            atomState.filters === filters &&
            atomState._resetVerson === resetVersion
        ) {
            return atomState;
        }
    },
    set: (_) => ({set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(groupEnrollmentListAtom, newValue);
    },
});

export const groupEnrollmentListReadSelector = selectorFamily<IGroupEnrollmentListState, IGroupEnrollmentListFilters>({
    key: 'groupEnrollmentListReadSelector',
    get: (filters) => async ({get}) => {
        const state = get(groupEnrollmentListSelector(filters));
        if (state) {
            return state;
        }

        const atomState = get(groupEnrollmentListAtom);
        const resetVersion = get(groupEnrollmentListResetAtom);
        const tenantId = get(tenantIdSelector);

        const {group_enrollments, next_cursor, more} = await readGroupEnrollmentList(tenantId, {
            group_id: filters.groupId,
            user_id: filters.userId,
            enrollment_type: filters.enrollmentType,
            invited_by_id: filters.invitedById,
            is_deleted: filters.isDeleted,
            limit: filters.limit,
            cursor: (filters.page === 0 || !atomState) ? undefined : atomState.cursors[filters.page - 1],
        });

        const cursorList = atomState ? [...atomState.cursors] : [];
        cursorList[filters.page] = next_cursor;

        return {
            groupEnrollments: group_enrollments,
            cursors: cursorList,
            more,
            filters,
            _resetVerson: resetVersion,
        };
    },
});

export const groupEnrollmentListInsertSelector = selector<IGroupEnrollment>({
    key: 'groupEnrollmentListInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const atomState = get(groupEnrollmentListAtom);

        if (!atomState) {
            return;
        }

        let groupEnrollments = [...atomState.groupEnrollments];
        const index = groupEnrollments.findIndex(groupEnrollment => {
            return groupEnrollment.group_id === newValue.group_id && groupEnrollment.user_id === newValue.user_id;
        });
        if (index !== -1) {
            groupEnrollments.splice(index, 1, newValue);
        } else {
            groupEnrollments = [...groupEnrollments, newValue];
        }
        set(groupEnrollmentListAtom, {...atomState, groupEnrollments});
    },
});

export const groupEnrollmentListRemoveSelector = selector<IGroupEnrollmentKey>({
    key: 'groupEnrollmentListRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const atomState = get(groupEnrollmentListAtom);
        if (!atomState) {
            return;
        }

        const groupEnrollments = [...atomState.groupEnrollments];
        const index = groupEnrollments.findIndex(groupEnrollment => {
            return groupEnrollment.group_id === newValue.group_id && groupEnrollment.user_id === newValue.user_id;
        });
        if (index !== -1) {
            groupEnrollments.splice(index, 1);
            set(groupEnrollmentListAtom, {...atomState, groupEnrollments});
        } else {
            set(groupEnrollmentListResetAtom, get(groupEnrollmentListResetAtom) + 1);
        }
    },
});

export const groupEnrollmentListBatchInsertSelector = selector<IGroupEnrollment[]>({
    key: 'groupEnrollmentListBatchInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const atomState = get(groupEnrollmentListAtom);

        if (!atomState) {
            return;
        }

        const groupEnrollments = [...atomState.groupEnrollments];
        let newGroupEnrollments: IGroupEnrollment[] = [];
        newValue.forEach((newGroupEnrollment) => {
            const index = groupEnrollments.findIndex(groupEnrollment => {
                return (
                    groupEnrollment.group_id === newGroupEnrollment.group_id &&
                    groupEnrollment.user_id === newGroupEnrollment.user_id
                );
            });
            if (index !== -1) {
                groupEnrollments.splice(index, 1, newGroupEnrollment);
            } else {
                newGroupEnrollments = [...newGroupEnrollments, newGroupEnrollment];
            }
        });
        set(groupEnrollmentListAtom, {
            ...atomState,
            groupEnrollments: [...groupEnrollments, ...newGroupEnrollments],
        });
    },
});

export const groupEnrollmentListBatchRemoveSelector = selector<IGroupEnrollment[]>({
    key: 'groupEnrollmentListBatchRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const atomState = get(groupEnrollmentListAtom);

        if (!atomState) {
            return;
        }

        const groupEnrollments = [...atomState.groupEnrollments];
        newValue.forEach((newGroupEnrollment) => {
            const index = groupEnrollments.findIndex(groupEnrollment => {
                return (
                    groupEnrollment.group_id === newGroupEnrollment.group_id &&
                    groupEnrollment.user_id === newGroupEnrollment.user_id
                );
            });
            if (index !== -1) {
                groupEnrollments.splice(index, 1);
            } else {
                set(groupEnrollmentListResetAtom, get(groupEnrollmentListResetAtom) + 1);
                return;
            }
        });
        set(groupEnrollmentListAtom, {...atomState, groupEnrollments});
    },
});

export const groupEnrollmentListResetSelector = selector<undefined>({
    key: 'groupEnrollmentListResetSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}) => {
        set(groupEnrollmentListResetAtom, get(groupEnrollmentListResetAtom) + 1);
    },
});