import React from 'react';

import {useTranslation} from 'react-i18next';
import {Badge, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {useSetRecoilState} from 'recoil';
import {toast} from 'react-toastify';

import {ICourse} from 'modules/course/models';
import {assignmentListRemoveSelector} from 'modules/assignment/state/assignment-list';
import {formatAxiosErrorOrThrow} from 'shared/utils/error';
import {unAssignAssignment} from 'modules/assignment/api';
import {useGuaranteedAuth} from 'shared/hooks/useAuth';

import {UserCourseProgress} from 'shared/components/UserCourseProgress/UserCourseProgress';

interface IProfilePageCourseTableRowProps {
    userId: number;
    course: ICourse;
}

export const ProfilePageCourseTableRow = ({userId, course}: IProfilePageCourseTableRowProps) => {
    const {t} = useTranslation();
    const {tenantId} = useGuaranteedAuth();
    const removeAssignment = useSetRecoilState(assignmentListRemoveSelector);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (isSubmitting) {
            return;
        }
        (async () => {
            try {
                setIsSubmitting(true);
                await unAssignAssignment(tenantId, userId, course.id);
                removeAssignment({
                    user_id: userId,
                    course_id: course.id,
                });
                toast.success(t('profilePageContent.unenrol.successMessage', 'Successfully unenrolled from course.'));
            } catch (e) {
                toast.error(formatAxiosErrorOrThrow(t(
                    'profilePageContent.errors.unenrol',
                    'There was an error un-enrolling you from the course',
                ), e));
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <tr>
            <td className="ProfilePageContent--course-title">
                <Link to={`/courses/${course.id}`}>{course.name}</Link>
                <Badge variant="success" className="ml-1">{t('common.published', 'Published')}</Badge>
            </td>
            <td className="align-middle">
                <UserCourseProgress courseId={course.id} userId={userId} />
            </td>
            <td className="ProfilePageContent--grade">--</td>
            <td className="ProfilePageContent--unenrol-button">
                <Button variant="danger" onClick={onClick} disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </td>
        </tr>
    );
};
