import axios from 'axios';

import {
    IGroupEnrollment,
    IGroupEnrollmentBatch,
    IGroupEnrollmentBatchResult,
    IGroupEnrollmentCount,
    IGroupEnrollmentCreate,
    IGroupEnrollmentCreateKeys,
    IGroupEnrollmentKey,
    IGroupEnrollmentKeys,
    IGroupEnrollmentListQuery,
    IGroupEnrollmentListResult,
    IGroupEnrollmentQuery,
} from 'modules/group-enrollment/models';
import {Config} from 'shared/config';
import {getAxiosOptions} from 'shared/utils/api';
import {getAccessTokenSilently} from 'shared/utils/token';

const getGroupEnrollmentUrl = (tenantId: number, path: string): string => {
    return `${Config.apiURL}/tenants/${tenantId}/group_enrollments${path}`;
};

export const createGroupEnrollment = async (
    tenantId: number,
    groupEnrollmentsCreate: IGroupEnrollmentCreate,
): Promise<IGroupEnrollment> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getGroupEnrollmentUrl(tenantId, '/'),
        groupEnrollmentsCreate,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const readGroupEnrollment = async (
    tenantId: number,
    groupEnrollmentKey: IGroupEnrollmentKey,
): Promise<IGroupEnrollment> => {
    const {groupId, userId} = groupEnrollmentKey;
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(
        getGroupEnrollmentUrl(tenantId, `/groups/${groupId}/users/${userId}`),
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const readGroupEnrollmentList = async (
    tenantId: number,
    groupEnrollmentListQuery: IGroupEnrollmentListQuery,
): Promise<IGroupEnrollmentListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(
        getGroupEnrollmentUrl(tenantId, '/'),
        {
            params: groupEnrollmentListQuery,
            ...getAxiosOptions(accessToken),
        },
    );
    return response.data;
};

export const createGroupEnrollmentBatch = async (
    tenantId: number,
    createGroupEnrollmentKeys: IGroupEnrollmentCreateKeys,
): Promise<IGroupEnrollmentBatch> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getGroupEnrollmentUrl(tenantId, '/batch/create'),
        createGroupEnrollmentKeys,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const readGroupEnrollmentBatch = async (
    tenantId: number,
    groupEnrollmentKeys: IGroupEnrollmentKeys,
): Promise<IGroupEnrollmentBatch> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getGroupEnrollmentUrl(tenantId, '/batch'),
        groupEnrollmentKeys,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const readGroupEnrollmentCount = async (
    tenantId: number,
    groupEnrollmentQuery: IGroupEnrollmentQuery,
): Promise<IGroupEnrollmentCount> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(
        getGroupEnrollmentUrl(tenantId, '/count'),
        {
            params: groupEnrollmentQuery,
            ...getAxiosOptions(accessToken),
        },
    );
    return response.data;
};

export const deleteGroupEnrollment = async (
    tenant_id: number,
    groupEnrollmentKey: IGroupEnrollmentKey,
) => {
    const {groupId, userId} = groupEnrollmentKey;
    const accessToken = await getAccessTokenSilently();
    const response = await axios.delete(
        getGroupEnrollmentUrl(tenant_id, `groups/${groupId}/users/${userId}`),
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const deleteGroupEnrollmentBatch = async (
    tenantId: number,
    groupEnrollmentKeys: IGroupEnrollmentKeys,
): Promise<IGroupEnrollmentBatchResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getGroupEnrollmentUrl(tenantId, '/batch/delete'),
        groupEnrollmentKeys,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const unEnrollGroupEnrollment = async (
    tenantId: number,
    groupEnrollmentKey: IGroupEnrollmentKey,
) => {
    const {groupId, userId} = groupEnrollmentKey;
    const accessToken = await getAccessTokenSilently();
    await axios.patch(
        getGroupEnrollmentUrl(tenantId, `/groups/${groupId}/users/${userId}/un_enroll`),
        undefined,
        getAxiosOptions(accessToken),
    );
};