import {useRecoilState} from 'recoil';

import {IGroup} from 'modules/group/models';
import {useGuaranteedAuth} from 'shared/hooks/useAuth';
import {isIdSelectedSelector} from 'shared/state/selected-ids';

import {Checkbox} from 'shared/components/Checkbox/Checkbox';

interface IAddGroupModalRowProps {
    group: IGroup;
    disabled: boolean;
    selectorKey: string,
}

export const AddGroupModalRow = ({group, disabled, selectorKey}: IAddGroupModalRowProps) => {
    const {tenantId} = useGuaranteedAuth();

    const [isSelected, setIsSelected] = useRecoilState(isIdSelectedSelector({
        tenantId,
        key: selectorKey,
        id: group.id,
    }));

    return (
        <tr>
            <td>
                <Checkbox
                    id={`AddGroupModalRow.${group.id}`}
                    name={`AddGroupModalRow.${group.id}`}
                    checked={isSelected}
                    onChange={e => setIsSelected(e.target.checked)}
                    disabled={disabled}
                />
            </td>
            <td>{group.name}</td>
        </tr>
    );
};