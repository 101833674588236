import {atom, selectorFamily} from 'recoil';
import {guardRecoilDefaultValue} from 'shared/recoil/utils';

interface ISelectedIdsAtomValue {
    availableIds: number[];
    selectedIds: number[];
}

interface ISelectedIdsAtom {
    [key: string]: ISelectedIdsAtomValue;
}

interface ISelectedIdsKey {
    tenantId: number;
    key: string;

    [key: string]: number | string;
}

interface ISelectedIdKey {
    tenantId: number;
    key: string;
    id: number;

    [key: string]: number | string;
}

export const selectedIdsAtom = atom<{ [key: number]: ISelectedIdsAtom | undefined }>({
    key: 'selectedIdsAtom',
    default: {},
});

export const selectedIdsStateSelector = selectorFamily<ISelectedIdsAtomValue, ISelectedIdsKey>({
    key: 'selectedIdsStateSelector',
    get: ({tenantId, key}) => ({get}): ISelectedIdsAtomValue => {
        const atomState = get(selectedIdsAtom);
        return atomState[tenantId]?.[key] ?? {
            availableIds: [],
            selectedIds: [],
        };
    },
    set: ({tenantId, key}) => {
        return ({get, set}, newValue) => {
            if (guardRecoilDefaultValue(newValue)) {
                return;
            }
            const atomState = get(selectedIdsAtom);
            const newAtomState = Object.assign({}, atomState);
            const newAtomTenantState = Object.assign({}, atomState[tenantId] ?? {});
            newAtomTenantState[key] = newValue;
            newAtomState[tenantId] = newAtomTenantState;
            set(selectedIdsAtom, newAtomState);
        };
    },
});

export const isIdSelectedSelector = selectorFamily<boolean, ISelectedIdKey>({
    key: 'isIdSelectedSelector',
    get: ({tenantId, key, id}) => ({get}): boolean => {
        const selectedIdsState = get(selectedIdsStateSelector({
            tenantId,
            key,
        }));
        return selectedIdsState.selectedIds.indexOf(id) !== -1;
    },
    set: ({tenantId, key, id}) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const selector = selectedIdsStateSelector({
            tenantId,
            key,
        });
        const selectedIdsState = get(selector);
        const newSelectedIds = Array.from(selectedIdsState.selectedIds);
        const index = newSelectedIds.indexOf(id);
        if (newValue && index === -1) {
            newSelectedIds.push(id);
        } else if (!newValue && index !== -1) {
            newSelectedIds.splice(index, 1);
        }
        set(selector, {
            selectedIds: newSelectedIds,
            availableIds: Array.from(selectedIdsState.availableIds),
        });
    },
});

export const checkAllSelector = selectorFamily<boolean, ISelectedIdsKey>({
    key: 'checkAllSelector',
    get: ({tenantId, key}) => ({get}): boolean => {
        const selectedIdsState = get(selectedIdsStateSelector({
            tenantId,
            key,
        }));
        const notSelectedIndex = selectedIdsState.availableIds.findIndex(
            availableId => selectedIdsState.selectedIds.indexOf(availableId) === -1,
        );
        return notSelectedIndex === -1;
    },
    set: ({tenantId, key}) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const selector = selectedIdsStateSelector({
            tenantId,
            key,
        });
        const selectedIdsState = get(selector);
        const newAvailableIds = Array.from(selectedIdsState.availableIds);
        set(selector, {
            selectedIds: newValue ? newAvailableIds : [],
            availableIds: newAvailableIds,
        });
    },
});
