import React from 'react';

import {FormCheck} from 'react-bootstrap';
import classNames from 'classnames';

interface ICheckboxProps {
    name?: string;
    className?: string;
    checked: boolean;
    disabled?: boolean;
    id: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    type?: 'radio' | 'checkbox';
    value?: string;
}

export const Checkbox = ({
    className,
    checked,
    disabled,
    id,
    onChange,
    onBlur,
    children,
    name,
    type,
    value,
}: React.PropsWithChildren<ICheckboxProps>) => {
    const classNameComputed = classNames(className, {
        'custom-checkbox--checkbox-only': !children,
    });

    return (
        <FormCheck custom className={classNameComputed}>
            <FormCheck.Input
                className="custom-checkbox--checkbox"
                checked={checked}
                disabled={disabled}
                id={id}
                onChange={onChange}
                type={type}
                name={name}
                onBlur={onBlur}
                value={value}
            />
            <FormCheck.Label
                className={classNames({
                    'custom-control-label--checkbox-only': !children,
                })}
                htmlFor={id}
            >{children}</FormCheck.Label>
        </FormCheck>
    );
};
