import React from 'react';

import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useSetRecoilState} from 'recoil';
import {Badge, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import {groupEnrollmentListRemoveSelector} from 'modules/group-enrollment/state/group-enrollment-list';
import {IGroup} from 'modules/group/models';
import {deleteGroupEnrollment} from 'modules/group-enrollment/api';
import {useGuaranteedAuth} from 'shared/hooks/useAuth';
import {formatAxiosErrorOrThrow} from 'shared/utils/error';

interface IProfilePageGroupTableRowProps {
    userId: number;
    group: IGroup;
}

export const ProfilePageGroupTableRow = ({userId, group}: IProfilePageGroupTableRowProps) => {
    const {t} = useTranslation();
    const {tenantId} = useGuaranteedAuth();
    const removeEnrollment = useSetRecoilState(groupEnrollmentListRemoveSelector);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (isSubmitting) {
            return;
        }
        (async () => {
            try {
                setIsSubmitting(true);
                await deleteGroupEnrollment(tenantId, {
                    group_id: group.id,
                    user_id: userId,
                });
                removeEnrollment({
                    user_id: userId,
                    group_id: group.id,
                });
                toast.success(t('profilePageContent.unenrolClass.successMessage', 'Successfully unenrolled from class.'));
            } catch (e) {
                toast.error(formatAxiosErrorOrThrow(t(
                    'profilePageContent.errors.unenrolClass',
                    'There was an error un-enrolling you from the class',
                ), e));
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <tr>
            <td className="ProfilePageContent--course-title">
                <Link to={`/groups/${group.id}`}>{group.name}</Link>
                <Badge variant="success" className="ml-1">{t('common.published', 'Published')}</Badge>
            </td>
            <td className="align-middle">
                {/* <UserCourseProgress courseId={group.id} userId={userId} /> */}
            </td>
            <td className="ProfilePageContent--grade">--</td>
            <td className="ProfilePageContent--unenrol-button">
                <Button variant="danger" onClick={onClick} disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </td>
        </tr>
    );
};