import React from 'react';

import {Button, Table} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

import {useEnrolledGroupsLoadable} from 'modules/user/hooks/useEnrolledGroupsLoadable';

import {EmptyMessage} from 'shared/components/EmptyMessage/EmptyMessage';
import {LoadingError} from 'shared/components/loading/LoadingError/LoadingError';
import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import {ProfilePageGroupTableRow} from 'shared/components/ProfilePageContent/ProfilePageGroupTable/ProfilePageGroupTableRow';
import {AddGroupModal} from 'shared/components/ProfilePageContent/AddGroupModal/AddGroupModal';

interface IProfilePageGroupTableProps {
    userId: number;
    isCurrentUser: boolean;
}

export const ProfilePageGroupTable = ({userId, isCurrentUser}: IProfilePageGroupTableProps) => {
    const {t} = useTranslation();
    const [showAddGroupModal, setShowAddGroupModal] = React.useState<boolean>(false);

    const {groups: enrolledGroups, loading, error} = useEnrolledGroupsLoadable({
        userId,
        page: 0,
        limit: 100,
    });

    return (
        <div className='pt-5'>
            <h2>{t('profilePageContent.classes', 'Classes')}</h2>
            {loading && (
                <LoadingSpinner />
            )}
            {error && (
                <LoadingError>
                    {t('profilePageContent.error.classes', 'Error loading classes.')}
                </LoadingError>
            )}
            {enrolledGroups && !enrolledGroups.length && (
                <EmptyMessage>
                    {isCurrentUser ? (
                        <p>{t('profilePageContent.emptyUser.classes', 'You are currently not enrolled in any classes.')}</p>
                    ) : (
                        <>
                            <p>{t(
                                'profilePageContent.emptyOther.classes',
                                'This student is currently not enrolled in any classes.',
                            )}</p>
                            <Button className='w-100 mb-3' onClick={() => setShowAddGroupModal(true)}>
                                {t('common.addClasses', 'Add Classes')}
                            </Button>
                        </>
                    )}
                </EmptyMessage>
            )}
            {enrolledGroups && !!enrolledGroups.length && (
                <>
                    {isCurrentUser ? (
                        <p>{t(
                            'profilePageContent.descriptionUser.classes',
                            'You are currently enrolled in the following classes:',
                        )}</p>
                    ) : (
                        <div className='d-flex'>
                            <p>
                                {t(
                                    'profilePageContent.descriptionOthe.classesr',
                                    'This student is currently enrolled in the following classes:',
                                )}
                            </p>
                            <Button className='ml-auto mb-3' onClick={() => setShowAddGroupModal(true)}>
                                {t('common.addClasses', 'Add Classes')}
                            </Button>
                        </div>
                    )}
                    <Table className="border" striped={true}>
                        <thead>
                            <tr>
                                <th>{t('common.classTitle', 'Class Title')}</th>
                                <th>{t('common.classProgress', 'Class Progress')}</th>
                                {/* <th className="ProfilePageContent--grade">{t('common.grade', 'Grade')}</th> */}
                                <th className="ProfilePageContent--unenrol-title">{t('common.unenrol', 'Unenrol')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {enrolledGroups?.map(group => (
                                <ProfilePageGroupTableRow key={group.id} userId={userId} group={group} />
                            ))}
                        </tbody>
                    </Table>
                </>
            )}
            <AddGroupModal
                enrolledGroupIds={enrolledGroups ? (enrolledGroups.map(group => group.id)) : []}
                userId={userId}
                show={showAddGroupModal}
                onClose={() => setShowAddGroupModal(false)}
            />
        </div>
    );
};