import React from 'react';

import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useEnrolledCoursesLoadable } from 'modules/user/hooks/useEnrolledCoursesLoadable';

import { EmptyMessage } from 'shared/components/EmptyMessage/EmptyMessage';
import { LoadingError } from 'shared/components/loading/LoadingError/LoadingError';
import { LoadingSpinner } from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import { ProfilePageCourseTableRow } from 'shared/components/ProfilePageContent/ProfilePageCourseTable/ProfilePageCourseTableRow';
import { AddCourseModal } from 'shared/components/ProfilePageContent/AddCourseModal/AddCourseModal';

interface IProfilePageCourseTableProps {
    userId: number;
    isCurrentUser: boolean;
}

export const ProfilePageCourseTable = ({userId, isCurrentUser}: IProfilePageCourseTableProps) => {
    const {t} = useTranslation();
    const [showAddCourseModal, setShowAddCourseModal] = React.useState<boolean>(false);

    const {courses: enrolledCourses, loading, error} = useEnrolledCoursesLoadable({
        userId,
        page: 0,
        limit: 100,
    });
    
    return (
        <div className='pt-5'>
            <h2>{t('profilePageContent.courses', 'Courses')}</h2>
            {loading && (
                <LoadingSpinner />
            )}
            {error && (
                <LoadingError>
                    {t('profilePageContent.error.courses', 'Error loading courses.')}
                </LoadingError>
            )}
            {enrolledCourses && !enrolledCourses.length && (
                <EmptyMessage>
                    {isCurrentUser ? (
                        <p>{t('profilePageContent.emptyUser.courses', 'You are currently not enrolled in any courses.')}</p>
                    ) : (
                        <>
                            <p>{t(
                                'profilePageContent.emptyOther.courses',
                                'This student is currently not enrolled in any courses.',
                            )}</p>
                            <Button className='w-100 mb-3' onClick={() => setShowAddCourseModal(true)}>
                                {t('common.addCourses', 'Add Courses')}
                            </Button>
                        </>
                    )}
                </EmptyMessage>
            )}
            {enrolledCourses && !!enrolledCourses.length && (
                <>
                    {isCurrentUser ? (
                        <p>{t(
                            'profilePageContent.descriptionUser.courses',
                            'You are currently studying the following courses:',
                        )}</p>
                    ) : (
                        <div className='d-flex'>
                            <p>
                                {t(
                                    'profilePageContent.descriptionOther.courses',
                                    'This student is currently studying the following courses:',
                                )}
                            </p>
                            <Button className='ml-auto mb-3' onClick={() => setShowAddCourseModal(true)}>
                                {t('common.addCourses', 'Add Courses')}
                            </Button>
                        </div>
                    )}
                    <Table className="border" striped={true}>
                        <thead>
                            <tr>
                                <th>{t('common.courseTitle', 'Course Title')}</th>
                                <th>{t('common.courseProgress', 'Course Progress')}</th>
                                <th className="ProfilePageContent--grade">{t('common.grade', 'Grade')}</th>
                                <th className="ProfilePageContent--unenrol-title">{t('common.unenrol', 'Unenrol')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {enrolledCourses?.map(course => (
                                <ProfilePageCourseTableRow userId={userId} course={course} key={course.id} />
                            ))}
                        </tbody>
                    </Table>
                </>
            )}
            <AddCourseModal
                enrolledCourseIds={enrolledCourses ? (enrolledCourses.map(course => course.id)) : []}
                userId={userId}
                show={showAddCourseModal}
                onClose={() => setShowAddCourseModal(false)}
            />
        </div>
    );
};