import {useRecoilValue} from 'recoil';

import {useGroupEnrollmentListLoadable} from 'modules/group-enrollment/hooks/groupEnrollmentListLoadable';
import {useGroupBatchLoadable} from 'modules/group/hooks/useGroupBatchLoadable';
import {tenantIdSelector} from 'modules/tenant/state/tenant-info';

interface IUseEnrolledGroupsLoadableProps {
    userId: number;
    page: number;
    limit?: number;
}

export const useEnrolledGroupsLoadable = ({userId, page, limit}: IUseEnrolledGroupsLoadableProps) => {
    const tenantId = useRecoilValue(tenantIdSelector);
    const {groupEnrollmentList, loading, error} = useGroupEnrollmentListLoadable({
        tenantId,
        userId: userId,
        limit,
        page,
    });

    const {
        groups,
        loading: groupsLoading,
    } = useGroupBatchLoadable({group_ids: groupEnrollmentList?.groupEnrollments.map(enrollment => enrollment.group_id) ?? []});

    const isLoading = loading || groupsLoading;

    return {
        groups,
        loading: isLoading,
        error,
    };
};