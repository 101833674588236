import { IGroupBatchQuery } from 'modules/group/models';
import { groupBatchReadSelector, groupBatchSelector } from 'modules/group/state/group-batch';
import { useRecoilLoadable } from 'shared/recoil/hooks/useRecoilLoadable';

export const useGroupBatchLoadable = (groupBatchQuery: IGroupBatchQuery) => {
    const {data, loading, error} = useRecoilLoadable({
        state: groupBatchSelector(groupBatchQuery),
        loadable: groupBatchReadSelector(groupBatchQuery),
    });
    return {
        groups: data,
        loading,
        error,
    };
};