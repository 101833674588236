import axios from 'axios';

import {getAccessTokenSilently} from 'shared/utils/token';
import {getAxiosHeaders, getAxiosOptions} from 'shared/utils/api';
import {Config} from 'shared/config';
import {
    IGroup,
    IGroupBatchQuery,
    IGroupBatchResult,
    IGroupCourseEnrolmentBatch,
    IGroupCreate,
    IGroupListQuery,
    IGroupListResult,
    IGroupUpdate,
    IGroupUsersListResult,
    IGroupUsersListQuery,
} from 'modules/group/models';

const getGroupApiUrl = (tenantId: number, path: string) => {
    return `${Config.courseServiceURL}/tenants/${tenantId}/groups${path}`;
};

export const createGroup = async (tenantId: number, groupCreate: IGroupCreate): Promise<IGroup> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getGroupApiUrl(tenantId, '/'),
        groupCreate,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const updateGroup = async (tenantId: number, groupId: number, groupUpdate: IGroupUpdate): Promise<IGroup> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(
        getGroupApiUrl(tenantId, `/${groupId}`),
        groupUpdate,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const deleteGroup = async (tenantId: number, groupId: number): Promise<void> => {
    const accessToken = await getAccessTokenSilently();
    await axios.delete(getGroupApiUrl(tenantId, `/${groupId}`), getAxiosOptions(accessToken));
};

export const readGroup = async (tenantId: number, groupId: number): Promise<IGroup> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(
        getGroupApiUrl(tenantId, `/${groupId}`),
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const readGroupList = async (tenantId: number, groupListQuery: IGroupListQuery): Promise<IGroupListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(
        getGroupApiUrl(tenantId, '/'),
        {
            params: groupListQuery,
            headers: getAxiosHeaders(accessToken),
        },
    );
    return response.data;
};

export const addGroupCourse = async (tenantId: number, groupId: number, courseId: number): Promise<IGroup> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(
        getGroupApiUrl(tenantId, `/${groupId}/course/add`),
        {
            course_id: courseId,
        },
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const removeGroupCourse = async (tenantId: number, groupId: number, courseId: number): Promise<IGroup> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(
        getGroupApiUrl(tenantId, `/${groupId}/course/remove`),
        {
            course_id: courseId,
        },
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const addGroupCourseBatch = async (
    tenantId: number,
    groupId: number,
    groupEnrolmentBatch: IGroupCourseEnrolmentBatch,
): Promise<IGroup> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(
        getGroupApiUrl(tenantId, `/${groupId}/add_enrolments`),
        groupEnrolmentBatch,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const removeGroupCourseBatch = async (
    tenantId: number,
    groupId: number,
    groupEnrolmentBatch: IGroupCourseEnrolmentBatch,
): Promise<IGroup> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(
        getGroupApiUrl(tenantId, `/${groupId}/remove_enrolments`),
        groupEnrolmentBatch,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const readGroupBatch = async (tenantId: number, groupKeys: IGroupBatchQuery): Promise<IGroupBatchResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getGroupApiUrl(tenantId, '/batch'),
        groupKeys,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const addGroupOwner = async (tenantId: number, groupId: number, ownerId: number): Promise<IGroup> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(
        getGroupApiUrl(tenantId, `/${groupId}/owner/add`),
        {owner_id: ownerId},
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const removeGroupOwner = async (tenantId: number, groupId: number, ownerId: number): Promise<IGroup> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(
        getGroupApiUrl(tenantId, `/${groupId}/owner/remove`),
        {owner_id: ownerId},
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const readGroupUsersList = async (
    tenantId: number,
    groupId: number,
    groupUsersListQuery: IGroupUsersListQuery,
): Promise<IGroupUsersListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(
        getGroupApiUrl(tenantId, `/${groupId}/users`),
        {
            params: groupUsersListQuery,
            ...getAxiosOptions(accessToken),
        },
    );
    return response.data;
};