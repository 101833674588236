export enum GroupEnrollmentType {
    STUDENT = 'STUDENT',
    TEACHER = 'TEACHER',
}

export interface IGroupEnrollment {
    tenant_id: number;
    id: number;
    group_id: number;
    user_id: number;
    enrollment_type: GroupEnrollmentType;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    invited_by_id: number;
    is_deleted: boolean;
}

export interface IGroupEnrollmentCreate {
    group_id: number;
    user_id: number;
    enrollment_type: GroupEnrollmentType;
    invited_by_id: number;
}

export interface IGroupEnrollmentQuery {
    group_id?: number;
    user_id?: number;
    enrollment_type?: GroupEnrollmentType;
    invited_by_id?: number;
    is_deleted?: boolean;

    [key: string]: number | GroupEnrollmentType | boolean | string | undefined;
}

export interface IGroupEnrollmentListQuery extends IGroupEnrollmentQuery {
    limit?: number;
    cursor?: string;
}

export interface IGroupEnrollmentListResult {
    group_enrollments: IGroupEnrollment[];
    next_cursor?: string;
    more: boolean;
}

export interface IGroupEnrollmentKey {
    group_id: number;
    user_id: number;

    [key: string]: number;
}

export interface IGroupEnrollmentCreateKeys {
    group_enrollment_keys: IGroupEnrollmentCreate[];
}

export interface IGroupEnrollmentBatch {
    group_enrollments: IGroupEnrollment[];
}

export interface IGroupEnrollmentKeys {
    group_enrollment_keys: IGroupEnrollmentKey[];
}

export interface IGroupEnrollmentCount {
    count: number;
}

export interface IGroupEnrollmentBatchError {
    group_enrollment_key: IGroupEnrollmentKey;
    error_message: string;
}

export interface IGroupEnrollmentBatchResult {
    group_enrollments: IGroupEnrollment[];
    errors: IGroupEnrollmentBatchError[];
}