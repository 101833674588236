import { useRecoilState } from 'recoil';

import { ICourse } from 'modules/course/models';
import { useGuaranteedAuth } from 'shared/hooks/useAuth';
import { isIdSelectedSelector } from 'shared/state/selected-ids';

import { Checkbox } from 'shared/components/Checkbox/Checkbox';

interface IAddCourseModalRowProps {
    course: ICourse;
    disabled: boolean;
    selectorKey: string,
}

export const AddCourseModalRow = ({course, disabled, selectorKey}: IAddCourseModalRowProps) => {
    const {tenantId} = useGuaranteedAuth();

    const [isSelected, setIsSelected] = useRecoilState(isIdSelectedSelector({
        tenantId,
        key: selectorKey,
        id: course.id,
    }));

    return (
        <tr>
            <td>
                <Checkbox
                    id={`AddCourseModalRow.${course.id}`}
                    name={`AddCourseModalRow.${course.id}`}
                    checked={isSelected}
                    onChange={e => setIsSelected(e.target.checked)}
                    disabled={disabled}
                />
            </td>
            <td>{course.name}</td>
        </tr>
    );
};