import {useRecoilValue} from 'recoil';

import {tenantIdSelector} from 'modules/tenant/state/tenant-info';
import {useAssignmentListLoadable} from 'modules/assignment/hooks/useAssignmentListLoadable';
import {useCourseBatchLoadable} from 'modules/course/hooks/useCourseBatchLoadable';

interface IUseEnrolledCoursesLoadableProps {
    userId: number;
    page: number;
    limit?: number;
}

export const useEnrolledCoursesLoadable = ({userId, page, limit}: IUseEnrolledCoursesLoadableProps) => {
    const tenantId = useRecoilValue(tenantIdSelector);
    const {assignmentList, loading, error} = useAssignmentListLoadable({
        tenantId,
        page,
        filters: {
            user_id: userId,
            limit,
        },
    });
    const {
        courses,
        loading: coursesLoading,
    } = useCourseBatchLoadable(assignmentList?.assignments?.map(assignment => assignment.course_id) ?? []);

    const isLoading = loading || coursesLoading;

    return {
        courses,
        loading: isLoading,
        error,
    };
};
