import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Cookie from 'js-cookie';
import {differenceInSeconds} from 'date-fns';

import {Scope} from 'modules/auth/models';
import {Config} from 'shared/config';
import {getErrorMessage} from '../error';

export class AuthError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'AuthError';
    }
}

export interface IPasswordLoginParams {
    username: string;
    password: string;
}

export interface IAuthorizationCodeLoginParams {
    code: string;
}

interface IJwtData {
    sub: string;
    exp: number;
    scopes: string[];
}

export interface IAuthClientOptions {
    tenantId: number;
}

export interface IDecodeAccessTokenResult {
    userId: number;
    expiresAt: Date;
    scopes: Scope[];
}

interface IAuthenticateWithCredentialsProps {
    grant_type: 'password';
    username: string;
    password: string;

    [key: string]: string;
}

interface IAuthenticateWithCodeProps {
    grant_type: 'authorization_code';
    code: string;

    [key: string]: string;
}

export class AuthClient {
    public storageKey: string;
    public searchAuthStorageKey: string;
    public cookieName: string;
    public isAuthenticated: boolean;
    public headerName: string;
    public tokenExpiresAt: Date | null;
    public tenantId: number;
    public userId: number | null;
    public scopes: Scope[];
    public refreshTimeout: ReturnType<typeof setTimeout> | null;
    private accessToken: string | null;
    private accessTokenPromise: Promise<string> | null;
    private searchToken: string | null;
    private searchTokenPromise: Promise<string> | null;
    private searchTokenExpiresAt: Date | null;

    constructor(private options: IAuthClientOptions) {
        this.tenantId = options.tenantId;
        this.storageKey = Config.authStorageKey;
        this.searchAuthStorageKey = Config.searchAuthStorageKey;
        this.cookieName = Config.authCookieName;
        this.isAuthenticated = false;
        this.headerName = 'authorization';
        this.userId = null;
        this.tokenExpiresAt = null;
        this.refreshTimeout = null;
        this.scopes = [];
        this.accessToken = null;
        this.accessTokenPromise = null;
        this.searchToken = null;
        this.searchTokenPromise = null;
        this.searchTokenExpiresAt = null;

        if (this.readIsAuthenticatedCookie()) {
            const accessToken = localStorage.getItem(this.storageKey);
            if (accessToken) {
                try {
                    this.setAccessToken(accessToken);
                } catch {
                }
            }
            const searchToken = localStorage.getItem(this.searchAuthStorageKey);
            if (searchToken) {
                this.setSearchToken(searchToken);
            }
        }

    }

    setAccessToken(accessToken: string) {
        const {userId, expiresAt, scopes} = this.decodeAccessToken(accessToken);
        this.userId = userId;
        this.scopes = scopes;
        this.tokenExpiresAt = expiresAt;
        this.accessToken = accessToken;
        this.isAuthenticated = true;
        localStorage.setItem(this.storageKey, accessToken);
    }

    setSearchToken(searchToken: string) {
        const tokenData: IJwtData = jwtDecode(searchToken);
        this.searchTokenExpiresAt = new Date(tokenData.exp * 1000);
        this.searchToken = searchToken;
        localStorage.setItem(this.searchAuthStorageKey, searchToken);
    }

    /**
     * Clear the current access token
     */
    clearToken(): void {

        // Remove access token from local storage
        if (typeof (Storage) !== 'undefined') {
            // TODO: fix this
            sessionStorage.removeItem(this.storageKey);
            localStorage.removeItem(this.searchAuthStorageKey);
        }

        // Remove local variables
        this.userId = null;
        this.tokenExpiresAt = null;
        this.accessToken = null;
        this.isAuthenticated = false;
        this.searchToken = null;
        this.searchTokenExpiresAt = null;
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
    }

    async authenticate(params: IAuthenticateWithCredentialsProps | IAuthenticateWithCodeProps): Promise<string> {
        const url = `${Config.userServiceURL}/token/`;

        const formData = new FormData();
        formData.append('client_id', this.tenantId?.toString() || '');
        Object.keys(params).forEach(key => formData.append(key, params[key]));

        const response = await axios.post(url, formData, {
            withCredentials: true,
        });
        const accessToken = response.data.access_token;
        this.setAccessToken(accessToken);
        return accessToken;
    }

    async authenticateWithCode({code}: IAuthorizationCodeLoginParams): Promise<string> {
        return await this.authenticate({
            grant_type: 'authorization_code',
            code,
        });
    }

    async authenticateWithCredentials({username, password}: IPasswordLoginParams): Promise<string> {
        return await this.authenticate({
            grant_type: 'password',
            username,
            password,
        });
    }

    async fetchAccessToken(): Promise<string> {
        const formData = new FormData();
        formData.append('grant_type', 'refresh_token');
        formData.append('client_id', this.tenantId.toString());

        try {
            const response = await axios.post(
                `${Config.userServiceURL}/token/`,
                formData,
                {
                    withCredentials: true,
                },
            );
            const accessToken = response.data.access_token;
            this.setAccessToken(accessToken);
            return accessToken;
        } catch (error) {
            this.clearToken();
            const errorMessage = axios.isAxiosError(error) ? getErrorMessage(error) : (error as Error).message;
            throw new AuthError(errorMessage);
        } finally {
            // clear the promise
            this.accessTokenPromise = null;
        }
    }

    async fetchSearchToken(): Promise<string> {
        try {
            const response = await axios.post(
                `${Config.userServiceURL}/tenants/${this.tenantId}/users/${this.userId}/search/token`,
                undefined,
                {
                    headers: {
                        'authorization': `Bearer ${this.accessToken}`,
                    },
                },
            );
            const searchToken = response.data.search_token;
            this.setSearchToken(searchToken);
            return searchToken;
        } catch (error) {
            this.clearToken();
            const errorMessage = axios.isAxiosError(error) ? getErrorMessage(error) : (error as Error).message;
            throw new AuthError(errorMessage);
        } finally {
            // clear the promise
            this.searchTokenPromise = null;
        }
    }

    async getAccessTokenSilently(): Promise<string> {
        if (!this.readIsAuthenticatedCookie()) {
            this.clearToken();
            throw new AuthError('You need to sign in again');
        }
        if (this.accessToken && this.tokenExpiresAt && differenceInSeconds(this.tokenExpiresAt, new Date()) > 30) {
            // TODO: Check whether requested scopes differ from current scopes. If they differ then don't use the
            //  cached token
            return this.accessToken;
        }

        // return existing promise if in progress, or create a new promise
        if (!this.accessTokenPromise) {
            this.accessTokenPromise = this.fetchAccessToken();
        }
        return await this.accessTokenPromise;
    }

    async getSearchTokenSilently(): Promise<string> {
        if (!this.readIsAuthenticatedCookie()) {
            this.clearToken();
            throw new AuthError('You need to sign in again');
        }
        if (this.searchToken && this.searchTokenExpiresAt && differenceInSeconds(this.searchTokenExpiresAt, new Date()) > 30) {
            return this.searchToken;
        }
        // return existing promise if in progress, or create a new promise
        if (!this.searchTokenPromise) {
            this.searchTokenPromise = this.fetchSearchToken();
        }
        return await this.searchTokenPromise;
    }

    async signOut(): Promise<void> {
        const wasAuthenticated = this.isAuthenticated;
        try {
            await axios.get(`${Config.userServiceURL}/logout/`, {
                withCredentials: true,
            });
            this.clearToken();
            if (wasAuthenticated) {
                window.location.pathname = '/';
            }
            return;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(getErrorMessage(error));
            } else if (error instanceof Error) {
                throw new Error(error.message);
            }
            throw error;
        }
    }

    private readIsAuthenticatedCookie(): boolean {
        const cookie = Cookie.get(this.cookieName);
        return !!cookie;
    }

    private decodeAccessToken(accessToken: string): IDecodeAccessTokenResult {
        const tokenData: IJwtData = jwtDecode(accessToken);
        return {
            userId: parseInt(tokenData.sub),
            expiresAt: new Date(tokenData.exp * 1000),
            scopes: tokenData.scopes.map(scope => scope as Scope),
        };
    }
}
