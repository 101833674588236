import React from 'react';

import {Button, Modal, Table} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {useRecoilState, useSetRecoilState} from 'recoil';

import {useGroupListLoadable} from 'modules/group/hooks/useGroupListLoadable';
import {IGroupListFilters} from 'modules/group/state/group-list';
import {useGuaranteedAuth} from 'shared/hooks/useAuth';
import {checkAllSelector, selectedIdsStateSelector} from 'shared/state/selected-ids';
import {IPaginationFilters, Pagination} from 'shared/components/Pagination/Pagination';
import {GroupEnrollmentType, IGroupEnrollmentCreate} from 'modules/group-enrollment/models';
import {createGroupEnrollmentBatch} from 'modules/group-enrollment/api';
import {formatAxiosErrorOrThrow} from 'shared/utils/error';
import {groupEnrollmentListBatchInsertSelector} from 'modules/group-enrollment/state/group-enrollment-list';

import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import {Checkbox} from 'shared/components/Checkbox/Checkbox';
import {AddGroupModalRow} from 'shared/components/ProfilePageContent/AddGroupModal/AddGroupModalRow';

interface IAddGroupModalContent {
    enrolledGroupIds: number[];
    userId: number;
    onClose: () => void;
}

export const AddGroupModalContent = ({enrolledGroupIds, userId, onClose}: IAddGroupModalContent) => {
    const {tenantId, tokenData} = useGuaranteedAuth();
    const {t} = useTranslation();

    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const selectorKey = 'AddGroupModal';

    const [filters, setFilters] = React.useState<IGroupListFilters>({
        tenantId,
        filters: {
            limit: 10,
        },
        page: 0,
    });

    const {groups, loading, error} = useGroupListLoadable(filters);

    const [isAllChecked, setIsAllChecked] = useRecoilState(checkAllSelector({
        tenantId,
        key: selectorKey,
    }));

    const paginationFilters: IPaginationFilters = {
        page: filters.page,
        limit: filters.filters?.limit ?? 10,
        showing: groups?.groups.length ?? 0,
    };

    const [selectedGroups, setSelectedIdState] = useRecoilState(selectedIdsStateSelector({
        tenantId,
        key: selectorKey,
    }));

    React.useEffect(() => {
        setSelectedIdState({
            availableIds: groups?.groups.map(group => group.id) ?? [],
            selectedIds: enrolledGroupIds,
        });
    }, [enrolledGroupIds, groups?.groups, setSelectedIdState]);

    const insertGroupEnrollments = useSetRecoilState(groupEnrollmentListBatchInsertSelector);

    const currentlySelectedIds = React.useMemo(() => {
        return selectedGroups.selectedIds.flatMap((groupId) => enrolledGroupIds.includes(groupId) ? [] : [groupId]);
    }, [enrolledGroupIds, selectedGroups.selectedIds]);

    const onSubmit = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        const enrollments: IGroupEnrollmentCreate[] = currentlySelectedIds.map(groupId => {
            return {
                group_id: groupId,
                user_id: userId,
                enrollment_type: GroupEnrollmentType.STUDENT,
                invited_by_id: tokenData.id,
            };
        });
        (async () => {
            try {
                const response = await createGroupEnrollmentBatch(tenantId, {
                    group_enrollment_keys: enrollments,
                });
                insertGroupEnrollments(response.group_enrollments);
                toast.success(t(
                    'AddGroupModal.success',
                    'Success. The user was enrolled in the classes.',
                ));
                onClose();
            } catch (e) {
                toast.error(formatAxiosErrorOrThrow(t(
                    'AddGroupModal.error',
                    'Error enrolling user in selected classes',
                ), e));
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <Modal.Body>
            {loading && (
                <LoadingSpinner />
            )}
            {error && (
                <p>error</p>
            )}
            {!!groups && (
                <>
                    <Pagination
                        isLoading={loading}
                        filters={paginationFilters}
                        onChange={(page: number) => setFilters({...filters, page})}
                    >
                        <Button onClick={onSubmit} disabled={isSubmitting || currentlySelectedIds.length === 0}>
                            {t('common.enrol', 'Enrol')}
                        </Button>
                        <span className="ml-2 font-weight-bold">
                            {t(
                                'AddGroupModal.selected',
                                '{{number}} Selected',
                                {number: currentlySelectedIds.length},
                            )}
                        </span>
                    </Pagination>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th className="table-cell--checkbox">
                                    <Checkbox
                                        id="AddGroupModalContent.all"
                                        name="AddGroupModalContent.all"
                                        checked={isAllChecked}
                                        onChange={e => setIsAllChecked(e.target.checked)}
                                    />
                                </th>
                                <th>{t('common.groupTitle', 'Class Title')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groups.groups.map(group => (
                                <AddGroupModalRow
                                    key={group.id}
                                    group={group}
                                    disabled={enrolledGroupIds.includes(group.id)}
                                    selectorKey={selectorKey}
                                />
                            ))}
                        </tbody>
                    </Table>
                </>
            )}
        </Modal.Body>
    );
};
