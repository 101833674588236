import { IGroupListFilters, groupListReadSelector, groupListSelector } from 'modules/group/state/group-list';
import { useRecoilLoadable } from 'shared/recoil/hooks/useRecoilLoadable';

export const useGroupListLoadable = (filters: IGroupListFilters) => {
    const {data, loading, error} = useRecoilLoadable({
        state: groupListSelector(filters),
        loadable: groupListReadSelector(filters),
    });

    return {
        groups: data,
        loading,
        error,
    };
};