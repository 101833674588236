import { IGroupEnrollmentListFilters, groupEnrollmentListReadSelector, groupEnrollmentListSelector } from 'modules/group-enrollment/state/group-enrollment-list';
import { useRecoilLoadable } from 'shared/recoil/hooks/useRecoilLoadable';

export const useGroupEnrollmentListLoadable = (filters: IGroupEnrollmentListFilters) => {
    const {data, loading, error} = useRecoilLoadable({
        state: groupEnrollmentListSelector(filters),
        loadable: groupEnrollmentListReadSelector(filters),
    });

    return {
        groupEnrollmentList: data,
        loading,
        error,
    };
};