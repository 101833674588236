import {Container} from 'react-bootstrap';

import {IUser} from 'modules/user/models/user';
import {useTitle} from 'shared/hooks/useTitle';
import {getFullName} from 'modules/user/api/user';
import {useNavbar} from 'shared/hooks/useNavbar';
import {useGuaranteedAuth} from 'shared/hooks/useAuth';

import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';
import {ProfilePageGroupTable} from 'shared/components/ProfilePageContent/ProfilePageGroupTable/ProfilePageGroupTable';
import {ProfilePageCourseTable} from 'shared/components/ProfilePageContent/ProfilePageCourseTable/ProfilePageCourseTable';
import {CourseRecordTable} from 'shared/components/ProfilePageContent/CourseRecordTable/CourseRecordTable';

import './style.scss';

interface IMyProfilePageContentProps {
    user: IUser;
}

export const ProfilePageContent = ({user}: IMyProfilePageContentProps) => {
    useNavbar(true);
    const {tokenData} = useGuaranteedAuth();
    
    const userFullName = getFullName(user);
    useTitle(userFullName);

    const isCurrentUser = user.id === tokenData.id;

    return (
        <>
            <Container className="pt-3 pb-xl">
                <h1>{userFullName}</h1>
                <ProfilePicture
                    alt={userFullName}
                    imageId={user.image?.file_id}
                    fileName={user.image?.filename}
                    round
                />
                <CourseRecordTable userId={user.id} />
                <ProfilePageCourseTable
                    userId={user.id}
                    isCurrentUser={isCurrentUser}
                />
                <ProfilePageGroupTable
                    userId={user.id}
                    isCurrentUser={isCurrentUser}
                />
            </Container>
        </>
    );
};
