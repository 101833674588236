import { atom, selector, selectorFamily } from 'recoil';

import { guardRecoilDefaultValue, throwWriteOnlySelectorError } from 'shared/recoil/utils';
import { tenantIdSelector } from 'modules/tenant/state/tenant-info';
import { IGroup, IGroupListQuery } from 'modules/group/models';
import { readGroupList } from 'modules/group/api';
import { CursorList } from 'shared/models/cursor-list';

export interface IGroupListFilters {
    filters: IGroupListQuery;
    page: number;

    [key: string]: IGroupListQuery | number | CursorList | boolean;
}

interface IGroupListState {
    groups: IGroup[];
    filters: IGroupListFilters;
    cursors: CursorList;
    more: boolean;

    _resetVersion: number;
}

export const groupListAtom = atom<IGroupListState | undefined>({
    key: 'groupListAtom',
    default: undefined,
});

export const groupListResetAtom = atom<number>({
    key: 'groupListResetAtom',
    default: 0,
});

export const groupListSelector = selectorFamily<IGroupListState | undefined, IGroupListFilters>({
    key: 'groupListSelector',
    get: (filters) => ({get}) => {
        const atomState = get(groupListAtom);
        const resetVersion = get(groupListResetAtom);
        if (
            atomState && 
            JSON.stringify(atomState.filters) === JSON.stringify(filters) && 
            resetVersion === atomState._resetVersion
        ) {
            return atomState;
        }
        return undefined;
    },
    set: () => ({set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(groupListAtom, newValue);
    },
});

export const groupListReadSelector = selectorFamily<IGroupListState, IGroupListFilters>({
    key: 'groupListReadSelector',
    get: ({filters, page}) => async ({get}) => {
        const state = get(groupListSelector({filters, page}));
        if (state) {
            return state;
        }

        const atomState = get(groupListAtom);

        const tenantId = get(tenantIdSelector);
        const response = await readGroupList(tenantId, {
            limit: filters.limit,
            cursor: (page === 0 || !atomState) ? undefined : atomState.cursors[page - 1],
        });

        const newCursors = atomState ? [...atomState.cursors] : [];
        newCursors[page] = response.next_cursor;

        const resetVersion = get(groupListResetAtom);

        return {
            groups: response.groups,
            filters: {filters, page},
            cursors: newCursors,
            more: response.more,
            _resetVersion: resetVersion,
        } as IGroupListState;
    },
});

export const groupListInsertSelector = selector<IGroup>({
    key: 'groupListInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const atomState = get(groupListAtom);
        if (!atomState) {
            return;
        }
        const newGroups = [...atomState.groups];
        const index = newGroups.findIndex(val => val.id === newValue.id);
        if (index !== -1) {
            newGroups.splice(index, 1, newValue);
            set(groupListAtom, {
                ...atomState,
                groups: newGroups,
            });
        } else {
            set(groupListResetAtom, get(groupListResetAtom) + 1);
        }
    },
});

export const groupListRemoveSelector = selector<number>({
    key: 'groupListRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, groupId) => {
        if (guardRecoilDefaultValue(groupId)) {
            return;
        }
        const atomState = get(groupListAtom);
        if (!atomState) {
            return;
        }
        const newGroups = [...atomState.groups];
        const index = newGroups.findIndex(val => val.id === groupId);
        if (index !== -1) {
            newGroups.splice(index, 1);
            set(groupListAtom, 
                {
                    ...atomState,
                    groups: newGroups,
                },
            );
        } else {
            set(groupListResetAtom, get(groupListResetAtom) + 1);
        }
    },
});