import { atomFamily, selectorFamily } from 'recoil';

import { readGroupBatch } from 'modules/group/api';
import { IGroup, IGroupBatchQuery } from 'modules/group/models';
import { tenantIdSelector } from 'modules/tenant/state/tenant-info';
import { guardRecoilDefaultValue } from 'shared/recoil/utils';

interface IGroupBatchState {
    groups: IGroup[];
    keys: IGroupBatchQuery;
}

const groupBatchAtom = atomFamily<IGroupBatchState | undefined, IGroupBatchQuery>({
    key: 'groupBatchAtom',
    default: undefined,
});

export const groupBatchSelector = selectorFamily<IGroup[] | undefined, IGroupBatchQuery>({
    key: 'groupBatchSelector',
    get: (keys) => ({get}) => {
        const atomState = get(groupBatchAtom(keys));

        if (atomState) {
            return atomState.groups;
        }
    },
    set: (keys) => ({set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(groupBatchAtom(keys), {groups: newValue, keys});
    },
});

export const groupBatchReadSelector = selectorFamily<IGroup[], IGroupBatchQuery>({
    key: 'groupBatchReadSelector',
    get: (keys) => async ({get}) => {
        const state = get(groupBatchSelector(keys));

        if (state) return state;

        const tenantId = get(tenantIdSelector);
        const response = await readGroupBatch(tenantId, keys);

        return response.groups;
    },
});