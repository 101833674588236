import {Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

import {AddGroupModalContent} from 'shared/components/ProfilePageContent/AddGroupModal/AddGroupModalContent';

interface IAddGroupModal {
    enrolledGroupIds: number[];
    userId: number;
    show: boolean;
    onClose: () => void;
}

export const AddGroupModal = ({enrolledGroupIds, userId, show, onClose}: IAddGroupModal) => {
    const {t} = useTranslation();

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('common.addClasses', 'Add Classes')}</Modal.Title>
            </Modal.Header>
            <AddGroupModalContent enrolledGroupIds={enrolledGroupIds} userId={userId} onClose={onClose} />
        </Modal>
    );
};